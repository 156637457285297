
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import React, { useEffect, useState } from 'react';
import { StudentService } from '../service/StudentService';

const DataTableLazyDemo = () => {

    const [loading, setLoading] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);
    const [students, setStudents] = useState(null);
    const [selectAll, setSelectAll] = useState(false);
    const [selectedStudents, setSelectedStudents] = useState(null);
    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: 10,
        page: 1,
        sortField: null,
        sortOrder: null,
        filters: {
            'first_name': { value: '', matchMode: 'contains' },
            'father_name': { value: '', matchMode: 'contains' },
            'mother_name': { value: '', matchMode: 'contains' },
            's_m_school': { value: '', matchMode: 'contains' },
        }
    });

    const studentService = new StudentService();

    let loadLazyTimeout = null;

    useEffect(() => {
        loadLazyData();
    },[lazyParams]) // eslint-disable-line react-hooks/exhaustive-deps

    const loadLazyData = () => {
        setLoading(true);

        if (loadLazyTimeout) {
            clearTimeout(loadLazyTimeout);
        }

        //imitate delay of a backend call
        loadLazyTimeout = setTimeout(() => {
            studentService.getStudents({ lazyEvent: JSON.stringify(lazyParams) }).then(data => {
                setTotalRecords(data.length);
                setStudents(data);
                setLoading(false);
            });
        }, Math.random() * 1000 + 250);
    }

    const onPage = (event) => {
        setLazyParams(event);
    }

    const onSort = (event) => {
        setLazyParams(event);
    }

    const onFilter = (event) => {
        event['first'] = 0;
        setLazyParams(event);
    }

    const onSelectionChange = (event) => {
        const value = event.value;
        setSelectedStudents(value);
        setSelectAll(value.length === totalRecords);
    }

    const onSelectAllChange = (event) => {
        const selectAll = event.checked;

        if (selectAll) {
            studentService.getStudents().then(data => {
                setSelectAll(true);
                setSelectedStudents(data);
            });
        }
        else {
            setSelectAll(false);
            setSelectedStudents([]);
        }
    }

    const schoolBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                {/* <img alt={rowData.representative.name} src={`images/avatar/${rowData.representative.image}`} onError={(e) => e.target.src = 'https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} width={32} style={{ verticalAlign: 'middle' }} /> */}
                <span className="image-text">{rowData.s_m_school}</span>
            </React.Fragment>
        );
    }

    const fatherBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                {/* <img alt="flag" src="/images/flag/flag_placeholder.png" onError={(e) => e.target.src = 'https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} className={`flag flag-${rowData.country.code}`} width={30} /> */}
                <span className="image-text">{rowData.father_name}</span>
            </React.Fragment>
        );
    }

    return (
        <div>
            <div className="card">
                <DataTable value={students} lazy filterDisplay="row" responsiveLayout="scroll" dataKey="id"
                    paginator first={lazyParams.first} rows={10} totalRecords={totalRecords} onPage={onPage}
                    onSort={onSort} sortField={lazyParams.sortField} sortOrder={lazyParams.sortOrder}
                    onFilter={onFilter} filters={lazyParams.filters} loading={loading}
                    selection={selectedStudents} onSelectionChange={onSelectionChange}
                    selectAll={selectAll} onSelectAllChange={onSelectAllChange}>
                    <Column selectionMode="multiple" headerStyle={{ width: '3em' }}></Column>
                    <Column field="first_name" header="First Name" sortable filter filterPlaceholder="Search by Name" />
                    <Column field="father_name" sortable header="Father" filterField="father_name" body={fatherBodyTemplate} filter filterPlaceholder="Search by father" />
                    <Column field="mother_name" sortable filter header="Mother" filterPlaceholder="Search by mother" />
                    <Column field="s_m_school" header="School" body={schoolBodyTemplate} filter filterPlaceholder="Search by school" />
                </DataTable>
            </div>
        </div>
    );
}

export default DataTableLazyDemo;
                 