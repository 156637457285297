import axios from "axios";

const baseUrl = "https://vbcf-backend.techknowgram.net/api";
export class EcmemberService {

    getProductsSmall() {
        return axios.get('assets/demo/data/products-small.json').then(res => res.data.data);
    }

    getEcmember() {
        return axios.get(baseUrl + '/ecmembers').then(res => res.data);
    }

    createEcmember(Ecmember) {
        return axios
            .post(baseUrl + '/ecmembers', Ecmember)
            .then(response => response)
        // .catch(err => console.log(err))
        // .finally(() => this.loading = false)
    }

    deleteEcmember(id) {
        return axios
            .delete(baseUrl + `/ecmembers/${id}`)
            .then(response => response);
    }

    updateEcmember(id, Ecmember) {
        return axios
            .patch(baseUrl + `/ecmembers/${id}`, Ecmember)
            .then((res) => res);
    }

    getProductsWithOrdersSmall() {
        return axios.get('assets/demo/data/products-orders-small.json').then(res => res.data.data);
    }
}