import axios from 'axios';

const baseUrl = "https://vbcf-backend.techknowgram.net/api";

export class ProgramService {

    getProductsSmall() {
        return axios.get('assets/demo/data/products-small.json').then(res => res.data.data);
    }

    getPrograms() {
        return axios.get(baseUrl + '/programs').then(res => res.data);
    }

    createProgram(program) {
        return axios
            .post(baseUrl + '/programs', program)
            .then(response => response)
        // .catch(err => console.log(err))
        // .finally(() => this.loading = false)
    }

    deleteProgram(id) {
        return axios
            .delete(baseUrl + `/programs/${id}`)
            .then(response => response);
    }

    updateProgram(id, program) {
        return axios
            .patch(baseUrl + `/programs/${id}`, program)
            .then((res) => res);
    }

    getProductsWithOrdersSmall() {
        return axios.get('assets/demo/data/products-orders-small.json').then(res => res.data.data);
    }
}