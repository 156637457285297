import classNames from 'classnames';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Skeleton } from 'primereact/skeleton';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import React, { useEffect, useRef, useState } from 'react';
import { FooterService } from '../service/FooterService';


const Footers = () => {
    let emptyFooter = {
        id: null,
        title: '',
        copyright: '',
        address: '',
    };

    const [footers, setFooters] = useState(null);
    const [section, setSection] = useState("2020-21");
    const [FooterDialog, setFooterDialog] = useState(false);
    const [deleteFooterDialog, setDeleteFooterDialog] = useState(false);
    const [deleteFootersDialog, setDeleteFootersDialog] = useState(false);
    const [footer, setFooter] = useState(emptyFooter);
    const [selectedFooters, setSelectedFooters] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);

    useEffect(() => {
        const footers = new FooterService();
        footers.getFooter().then(data => setFooters(data));
    }, []);

    const openNew = () => {
        setFooter(emptyFooter);
        setSubmitted(false);
        setFooterDialog(true);
    }

    const hideDialog = () => {
        setSubmitted(false);
        setFooterDialog(false);
    }

    const hideDeleteFooterDialog = () => {
        setDeleteFooterDialog(false);
    }

    const hideDeleteFootersDialog = () => {
        setDeleteFootersDialog(false);
    }

    const saveFooter = () => {
        setSubmitted(true);

        if (footer.title.trim()) {
            let _Footers = [...footers];
            let _Footer = { ...footer };
            if (footer.id) {
                const index = findIndexById(footer.id);
                
                _Footers[index] = _Footer;

                //network request here
                const footerService = new FooterService();
                footerService.updateFooter(footer.id, _Footer).then(res => {
                    console.log(res);
                    if (res.status === 200) {
                        // _Ecmembers.push({..._Ecmember, image: base64Image});
                        setFooters(_Footers);
                        setFooterDialog(false);
                        setFooter(emptyFooter);
                        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Ecmember Updated', life: 3000 });
                    }
                });

            }
            else {
                _Footer.id = createId();

                //network request here
                const footerService = new FooterService();
                footerService.createFooter(_Footer).then(res => {
                    console.log(res);
                    if (res.status === 200) {
                        _Footers.unshift(_Footer);
                        setFooters(_Footers);
                        setFooterDialog(false);
                        setFooter(emptyFooter);
                        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Ecmember Created', life: 3000 });
                    }
                });

            }
        }
    }

    const editFooter = (Footer) => {
        setFooter({ ...Footer });
        setSection(Footer.address);
        setFooterDialog(true);
    }

    const confirmDeleteFooter = (Footer) => {
        setFooter(Footer);
        setDeleteFooterDialog(true);
    }

    const deleteFooter = () => {
        let _Footers = footers.filter(val => val.id !== footer.id);

        //network request here
        const footerService = new FooterService();
        footerService.deleteFooter(footer.id).then(res => {
            console.log(res);
            if (res.status === 200) {
                setFooters(_Footers);
                setDeleteFooterDialog(false);
                setFooter(emptyFooter);
                setSection("2020-21");
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Footer Deleted', life: 3000 });
            }
        });

    }

    const findIndexById = (id) => {
        let index = -1;
        for (let i = 0; i < footers.length; i++) {
            if (footers[i].id === id) {
                index = i;
                break;
            }
        }

        return index;
    }

    const createId = () => {
        let id = '';
        let chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        for (let i = 0; i < 5; i++) {
            id += chars.charAt(Math.floor(Math.random() * chars.length));
        }
        return id;
    }

    const exportCSV = () => {
        dt.current.exportCSV();
    }


    const deleteSelectedFooters = () => {
        let _Footers = Footers.filter(val => !selectedFooters.includes(val));
        setFooters(_Footers);
        setDeleteFooterDialog(false);
        setSelectedFooters(null);
        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Footer Deleted', life: 3000 });
    }

    //no need for now. since Ecmember has no category yet
    // const onCategoryChange = (e) => {
    //     let _product = { ...product };
    //     _product['category'] = e.value;
    //     setProduct(_product);
    // }

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _Footer = { ...footer };
        _Footer[`${name}`] = val;

        setFooter(_Footer);
    }


    // no need for now since Ecmembers has no number fields
    // const onInputNumberChange = (e, name) => {
    //     const val = e.value || 0;
    //     let _product = { ...product };
    //     _product[`${name}`] = val;

    //     setProduct(_product);
    // }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    {!footers && (
                        <Button label="New" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                    )}

                    {footers && !footers.length && (
                        <Button label="New" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />

                    )}
                    {/* <Button label="Delete" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedEcmembers || !selectedEcmembers.length} /> */}
                </div>
            </React.Fragment>
        )
    }

        const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                {/* <FileUpload mode="basic" accept="image/*" maxFileSize={1000000} label="Import" chooseLabel="Import" className="mr-2 inline-block" /> */}
                <Button label="Export" icon="pi pi-upload" className="p-button-help" onClick={exportCSV} />
            </React.Fragment>
        )
    }

    const titleBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Title</span>
                {rowData.title}
            </>
        );
    }


    const addressBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Address</span>
                {rowData.address}
            </>
        )
    }

    const copyrightBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Copyright</span>
                {rowData.copyright}
            </>
        );
    }
    const sectionBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Section</span>
                {rowData.section}
            </>
        );
    }

    //no need
    // const categoryBodyTemplate = (rowData) => {
    //     return (
    //         <>
    //             <span className="p-column-title">Category</span>
    //             {rowData.category}
    //         </>
    //     );
    // }

    // const ratingBodyTemplate = (rowData) => {
    //     return (
    //         <>
    //             <span className="p-column-title">Reviews</span>
    //             <Rating value={rowData.rating} readonly cancel={false} />
    //         </>
    //     );
    // }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editFooter(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-warning mt-2" onClick={() => confirmDeleteFooter(rowData)} />
            </div>
        );
    }

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Manage Footer </h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );

    const FooterDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={saveFooter} />
        </>
    );
    const deleteFooterDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteFooterDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteFooter} />
        </>
    );
    const deleteFootersDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteFootersDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedFooters} />
        </>
    );

    const placeholders = Array.from({ length: 10 });

    const bodyTemplate = () => {
        return <Skeleton></Skeleton>
    }



    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>
                    {!footers && (
                        <DataTable value={placeholders} className="p-datatable-striped">
                            <Column field="title" header="Title" style={{ width: '16%' }} body={bodyTemplate}></Column>
                            <Column field="address" header="Address" style={{ width: '16%' }} body={bodyTemplate}></Column>
                            <Column field="copyright" header="Copyright" style={{ width: '16%' }} body={bodyTemplate}></Column>
                        </DataTable>
                    )}

                    {footers && (

                        <DataTable ref={dt} value={footers} selection={selectedFooters} onSelectionChange={(e) => setSelectedFooters(e.value)}
                            dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Footers"
                            globalFilter={globalFilter} emptyMessage="No Footers found." header={header} responsiveLayout="scroll">
                            <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                            <Column field="title" header="Title" sortable body={titleBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                            <Column field="address" header="address" body={addressBodyTemplate} sortable headerStyle={{ width: '14%', minWidth: '8rem' }}></Column>
                            <Column field="copyright" header="copyright" body={copyrightBodyTemplate} sortable headerStyle={{ width: '14%', minWidth: '8rem' }}></Column>
                            <Column field="section" header="Session" body={sectionBodyTemplate} sortable headerStyle={{ width: '14%', minWidth: '8rem' }}></Column>
                            <Column body={actionBodyTemplate}></Column>
                        </DataTable>

                    )}



                    <Dialog visible={FooterDialog} style={{ width: '680px' }} header="Footer Details" modal className="p-fluid" footer={FooterDialogFooter} onHide={hideDialog}>

                        <div className="field">
                            <label htmlFor="title">Title</label>
                            <InputText id="title" value={footer.title} onChange={(e) => onInputChange(e, 'title')} required autoFocus className={classNames({ 'p-invalid': submitted && !footer.title })} />
                            {submitted && !footer.title && <small className="p-invalid">Title is required.</small>}
                        </div>


                        <div className="field">
                            <label htmlFor="address">Address</label>
                            <InputText id="address" value={footer.address} onChange={(e) => onInputChange(e, 'address')} required autoFocus className={classNames({ 'p-invalid': submitted && !footer.address })} />
                            {submitted && !footer.address && <small className="p-invalid">Address is required.</small>}
                        </div>

                        <div className="field">
                            <label htmlFor="copyright">Copyright</label>
                            <InputText id="copyright" value={footer.copyright} onChange={(e) => onInputChange(e, 'copyright')} required autoFocus className={classNames({ 'p-invalid': submitted && !footer.copyright })} />
                            {submitted && !footer.copyright && <small className="p-invalid">Copyright is required.</small>}
                        </div>
                    </Dialog>

                    <Dialog visible={deleteFooterDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteFooterDialogFooter} onHide={hideDeleteFooterDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {footer && <span>Are you sure you want to delete <b>{footer.title}</b>?</span>}
                        </div>
                    </Dialog>

                    <Dialog visible={deleteFootersDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteFootersDialogFooter} onHide={hideDeleteFootersDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {footer && <span>Are you sure you want to delete the selected Footers?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Footers, comparisonFn);