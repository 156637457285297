import classNames from 'classnames';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { FileUpload } from 'primereact/fileupload';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { RadioButton } from 'primereact/radiobutton';
import { Skeleton } from 'primereact/skeleton';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import React, { useEffect, useRef, useState } from 'react';
import { EventService } from '../service/EventService';
import { ProgramService } from '../service/ProgramService';



const Events = () => {

    let emptyEvent = {
        id: null,
        title: '',
        description: '',
        image: null,
        program_id: null
    };

    const [events, setEvents] = useState(null);
    const [programs, setPrograms] = useState(null);
    const [eventDialog, setEventDialog] = useState(false);
    const [deleteEventDialog, setDeleteEventDialog] = useState(false);
    const [event, setEvent] = useState(emptyEvent);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [base64Image, setBase64Image] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);

    const [selectedProgram, setSelectedProgram] = useState(null);


    useEffect(() => {
        const eventService = new EventService();
        eventService.getEvents().then(data => setEvents(data));

        const programService = new ProgramService();
        programService.getPrograms().then(data => {setPrograms(data); setSelectedProgram(data[0]) });
    }, []);

    const openNew = () => {
        setEvent(emptyEvent);
        setSubmitted(false);
        setEventDialog(true);
    }

    const hideDialog = () => {
        setSubmitted(false);
        setEventDialog(false);
    }

    const hideDeleteEventDialog = () => {
        setDeleteEventDialog(false);
    }

    const saveEvent = () => {
        setSubmitted(true);

        if (event.title.trim()) {
            let _events = [...events];
            let _event = { ...event };
            if (event.id) {
                const index = findIndexById(event.id);
                _event.image = base64Image;
                _event.program_id= selectedProgram.id
                _events[index] = _event;

                //network request here
                const eventService = new EventService();
                eventService.updateEvent(event.id, _event).then(res => {

                    if (res.status === 200) {
                        setEvents(_events);
                        setEventDialog(false);
                        setEvent(emptyEvent);
                        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Event Updated', life: 3000 });
                    }
                });

            }
            else {
                _event.id = createId();
                _event.image = base64Image;
                _event.program_id= selectedProgram.id


                //network request here
                const eventService = new EventService();
                eventService.createEvent(_event).then(res => {
                    console.log(res);
                    if (res.status === 200) {
                        _events.unshift(_event);
                        setEvents(_events);
                        setEventDialog(false);
                        setEvent(emptyEvent);
                        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Event Created', life: 3000 });
                    }
                });

            }
        }
    }

    const editEvent = (event) => {
        setEvent({ ...event });
        setBase64Image(event.image);
        setSelectedProgram(programs.find(program => program.id === event.program_id));
        setEventDialog(true);
    }

    const confirmDeleteEvent = (event) => {
        setEvent(event);
        setDeleteEventDialog(true);
    }

    const deleteEvent = () => {
        let _events = events.filter(val => val.id !== event.id);

        //network request here
        const eventService = new EventService();
        eventService.deleteEvent(event.id).then(res => {
            console.log(res);
            if (res.status === 200) {
                setEvents(_events);
                setDeleteEventDialog(false);
                setEvent(emptyEvent);
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Event Deleted', life: 3000 });
            }
        });

    }

    const findIndexById = (id) => {
        let index = -1;
        for (let i = 0; i < events.length; i++) {
            if (events[i].id === id) {
                index = i;
                break;
            }
        }

        return index;
    }

    const createId = () => {
        let id = '';
        let chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        for (let i = 0; i < 5; i++) {
            id += chars.charAt(Math.floor(Math.random() * chars.length));
        }
        return id;
    }

    const exportCSV = () => {
        dt.current.exportCSV();
    }


    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _event = { ...event };
        _event[`${name}`] = val;

        setEvent(_event);
    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="New" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                    {/* <Button label="Delete" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedPosts || !selectedPosts.length} /> */}
                </div>
            </React.Fragment>
        )
    }

    const customBase64Uploader = async (e) => {
        // convert file to base64 encoded 
        const file = e.files[0];
        const reader = new FileReader();
        let blob = await fetch(file.objectURL).then(r => r.blob()); //blob:url
        reader.readAsDataURL(blob);
        reader.onloadend = function () {
            const base64data = reader.result;
            console.log(base64data);
            setBase64Image(base64data);
            setEvent({ ...event, image: base64data });
        }
    }

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                {/* <FileUpload mode="basic" accept="image/*" maxFileSize={1000000} label="Import" chooseLabel="Import" className="mr-2 inline-block" /> */}
                <Button label="Export" icon="pi pi-upload" className="p-button-help" onClick={exportCSV} />
            </React.Fragment>
        )
    }

    const titleBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Title</span>
                {rowData.title}
            </>
        );
    }

    const descriptionBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Description</span>
                {rowData.description.substr(0, 50) + '...'}
            </>
        );
    }

    const imageBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Image</span>
                <img src={rowData.image} alt={rowData.image} className="shadow-2" width="100" />
            </>
        )
    }


    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editEvent(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-warning mt-2" onClick={() => confirmDeleteEvent(rowData)} />
            </div>
        );
    }

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Manage Events</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );

    const eventDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={saveEvent} />
        </>
    );
    const deleteEventDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteEventDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteEvent} />
        </>
    );

    const placeholders = Array.from({ length: 10 });

    const bodyTemplate = () => {
        return <Skeleton></Skeleton>
    }



    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>



                    {!events && (
                        <DataTable value={placeholders} className="p-datatable-striped">
                            <Column field="title" header="Title" style={{ width: '16%' }} body={bodyTemplate}></Column>
                            <Column field="description" header="Description" style={{ width: '16%' }} body={bodyTemplate}></Column>
                            <Column field="image" header="Image" style={{ width: '16%' }} body={bodyTemplate}></Column>
                        </DataTable>
                    )}

                    {events && (

                        <DataTable ref={dt} value={events}
                            dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} events"
                            globalFilter={globalFilter} emptyMessage="No events found." header={header} responsiveLayout="scroll">
                            <Column selectionMode="single" headerStyle={{ width: '3rem' }}></Column>
                            <Column field="title" header="Description" sortable body={titleBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                            <Column field="description" header="Description" sortable body={descriptionBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                            <Column header="Image" body={imageBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                            <Column body={actionBodyTemplate}></Column>
                        </DataTable>

                    )}



                    <Dialog visible={eventDialog} style={{ width: '680px' }} header="Event Details" modal className="p-fluid" footer={eventDialogFooter} onHide={hideDialog}>
                        {event.image && <img src={event.image} alt={event.image} width="150" className="mt-0 mx-auto mb-5 block shadow-2" />}

                        <div className="field">
                            <label htmlFor="title">Title</label>
                            <InputText id="title" value={event.title} onChange={(e) => onInputChange(e, 'title')} required autoFocus className={classNames({ 'p-invalid': submitted && !event.title })} />
                            {submitted && !event.title && <small className="p-invalid">Title is required.</small>}
                        </div>

                        <div className="field">
                            <label htmlFor="description">Description</label>
                            <InputTextarea id="description" value={event.description} onChange={(e) => onInputChange(e, 'description')} required rows={3} cols={20} />
                        </div>

                        <h5>Programs</h5>
                        {
                            eventDialog && programs && programs.map((program) => {
                                return (
                                    <div key={program.id} className="field-radiobutton">
                                        <RadioButton inputId={program.id} name="program" value={program} onChange={(e) => setSelectedProgram(e.value)} checked={selectedProgram.id === program.id} disabled={program.id === 'R'} />
                                        <label htmlFor={program.id}>{program.title}</label>
                                    </div>
                                )
                            })
                        }

                        <div className="field">
                            <label htmlFor="status">Image</label>
                            <FileUpload mode="basic" name="status" url="https://primefaces.org/primereact/showcase/upload.php" accept="image/*" customUpload uploadHandler={customBase64Uploader} auto />
                        </div>

                    </Dialog>

                    <Dialog visible={deleteEventDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteEventDialogFooter} onHide={hideDeleteEventDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {event && <span>Are you sure you want to delete <b>{event.title}</b>?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Events, comparisonFn);