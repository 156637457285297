import classNames from 'classnames';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { FileUpload } from 'primereact/fileupload';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { RadioButton } from 'primereact/radiobutton';
import { Skeleton } from 'primereact/skeleton';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import React, { useEffect, useRef, useState } from 'react';
import { ProgramService } from '../service/ProgramService';
import { TeacherService } from '../service/TeacherService';



const Teachers = () => {

    let emptyTeacher = {
        id: null,
        first_name: '',
        last_name: '',
        photo: '',
        email: '',
        phone: '',
        description: '',
        program_id: null
    };

    const [teachers, setTeachers] = useState(null);
    const [programs, setPrograms] = useState(null);
    const [teacherDialog, setTeacherDialog] = useState(false);
    const [deleteTeacherDialog, setDeleteTeacherDialog] = useState(false);
    const [teacher, setTeacher] = useState(emptyTeacher);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [base64Image, setBase64Image] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);
    const [selectedProgram, setSelectedProgram] = useState(null);


    useEffect(() => {
        const teacherService = new TeacherService();
        teacherService.getTeachers().then(data => setTeachers(data));

        const programService = new ProgramService();
        programService.getPrograms().then(data => {setPrograms(data); setSelectedProgram(data[0]) });
    }, []);

    const openNew = () => {
        setTeacher(emptyTeacher);
        setSubmitted(false);
        setTeacherDialog(true);
    }

    const hideDialog = () => {
        setSubmitted(false);
        setTeacherDialog(false);
    }

    const hideDeleteTeacherDialog = () => {
        setDeleteTeacherDialog(false);
    }

    const saveTeacher = () => {
        setSubmitted(true);

        if (teacher.first_name.trim()) {
            let _teachers = [...teachers];
            let _teacher = { ...teacher };
            if (teacher.id) {
                const index = findIndexById(teacher.id);
                _teacher.photo = base64Image;
                _teacher.program_id= selectedProgram.id;
                _teachers[index] = _teacher;

                //network request here
                const teacherService = new TeacherService();
                teacherService.updateTeacher(teacher.id, _teacher).then(res => {

                    if (res.status === 200) {
                        setTeachers(_teachers);
                        setTeacherDialog(false);
                        setTeacher(emptyTeacher);
                        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Teacher Updated', life: 3000 });
                    }
                });

            }
            else {
                _teacher.id = createId();
                _teacher.photo = base64Image;
                _teacher.program_id= selectedProgram.id;


                //network request here
                const teacherService = new TeacherService();
                teacherService.createTeacher(_teacher).then(res => {
                    console.log(res);
                    if (res.status === 200) {
                        _teachers.unshift(_teacher);
                        setTeachers(_teachers);
                        setTeacherDialog(false);
                        setTeacher(emptyTeacher);
                        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Teacher Created', life: 3000 });
                    }
                });

            }
        }
    }

    const editTeacher = (teacher) => {
        setTeacher({ ...teacher });
        setBase64Image(teacher.photo);
        setSelectedProgram(programs.find(program => program.id === teacher.program_id));
        setTeacherDialog(true);
    }

    const confirmDeleteTeacher = (teacher) => {
        setTeacher(teacher);
        setDeleteTeacherDialog(true);
    }

    const deleteTeacher = () => {
        let _teachers = teachers.filter(val => val.id !== teacher.id);

        //network request here
        const teacherService = new TeacherService();
        teacherService.deleteTeacher(teacher.id).then(res => {
            console.log(res);
            if (res.status === 200) {
                setTeachers(_teachers);
                setDeleteTeacherDialog(false);
                setTeacher(emptyTeacher);
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Teacher Deleted', life: 3000 });
            }
        });

    }

    const findIndexById = (id) => {
        let index = -1;
        for (let i = 0; i < teachers.length; i++) {
            if (teachers[i].id === id) {
                index = i;
                break;
            }
        }

        return index;
    }

    const createId = () => {
        let id = '';
        let chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        for (let i = 0; i < 5; i++) {
            id += chars.charAt(Math.floor(Math.random() * chars.length));
        }
        return id;
    }

    const exportCSV = () => {
        dt.current.exportCSV();
    }


    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _teacher = { ...teacher };
        _teacher[`${name}`] = val;

        setTeacher(_teacher);
    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="New" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                    {/* <Button label="Delete" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedPosts || !selectedPosts.length} /> */}
                </div>
            </React.Fragment>
        )
    }

    const customBase64Uploader = async (event) => {
        // convert file to base64 encoded 
        const file = event.files[0];
        const reader = new FileReader();
        let blob = await fetch(file.objectURL).then(r => r.blob()); //blob:url
        reader.readAsDataURL(blob);
        reader.onloadend = function () {
            const base64data = reader.result;
            console.log(base64data);
            setBase64Image(base64data);
            setTeacher({ ...teacher, photo: base64data });
        }
    }

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                {/* <FileUpload mode="basic" accept="image/*" maxFileSize={1000000} label="Import" chooseLabel="Import" className="mr-2 inline-block" /> */}
                <Button label="Export" icon="pi pi-upload" className="p-button-help" onClick={exportCSV} />
            </React.Fragment>
        )
    }

    const titleBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">First Name</span>
                {rowData.first_name}
            </>
        );
    }

    const contentBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Description</span>
                {rowData.description.substr(0, 50) + '...'}
            </>
        );
    }

    const imageBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Photo</span>
                <img src={rowData.photo} alt={rowData.photo} className="shadow-2" width="100" />
            </>
        )
    }

    const excerptBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Last Name</span>
                {rowData.last_name}
            </>
        );
    }

    const authorBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Email</span>
                {rowData.email}
            </>
        );
    }

    //no need
    // const categoryBodyTemplate = (rowData) => {
    //     return (
    //         <>
    //             <span className="p-column-title">Category</span>
    //             {rowData.category}
    //         </>
    //     );
    // }

    // const ratingBodyTemplate = (rowData) => {
    //     return (
    //         <>
    //             <span className="p-column-title">Reviews</span>
    //             <Rating value={rowData.rating} readonly cancel={false} />
    //         </>
    //     );
    // }

    const statusBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Phone</span>
                {rowData.phone}
            </>
        )
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editTeacher(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-warning mt-2" onClick={() => confirmDeleteTeacher(rowData)} />
            </div>
        );
    }

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Manage Teachers</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );

    const postDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={saveTeacher} />
        </>
    );
    const deleteTeacherDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteTeacherDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteTeacher} />
        </>
    );

    const placeholders = Array.from({ length: 10 });

    const bodyTemplate = () => {
        return <Skeleton></Skeleton>
    }



    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>



                    {!teachers && (
                        <DataTable value={placeholders} className="p-datatable-striped">
                            <Column field="first_name" header="First Name" style={{ width: '16%' }} body={bodyTemplate}></Column>
                            <Column field="last_name" header="Last Name" style={{ width: '16%' }} body={bodyTemplate}></Column>
                            <Column field="photo" header="Photo" style={{ width: '16%' }} body={bodyTemplate}></Column>
                            <Column field="email" header="Email" style={{ width: '16%' }} body={bodyTemplate}></Column>
                            <Column field="phone" header="Phone" style={{ width: '16%' }} body={bodyTemplate}></Column>
                            <Column field="description" header="Description" style={{ width: '16%' }} body={bodyTemplate}></Column>
                        </DataTable>
                    )}

                    {teachers && (

                        <DataTable ref={dt} value={teachers}
                            dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} teachers"
                            globalFilter={globalFilter} emptyMessage="No teachers found." header={header} responsiveLayout="scroll">
                            <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                            <Column field="first_name" header="First Name" sortable body={titleBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                            <Column field="last_name" header="Last Name" sortable body={excerptBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                            <Column header="Photo" body={imageBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                            <Column field="email" header="Email" body={authorBodyTemplate} sortable headerStyle={{ width: '14%', minWidth: '8rem' }}></Column>
                            <Column field="phone" header="Phone" body={statusBodyTemplate} sortable headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                            <Column field="description" header="Description" sortable body={contentBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                            <Column body={actionBodyTemplate}></Column>
                        </DataTable>

                    )}



                    <Dialog visible={teacherDialog} style={{ width: '680px' }} header="Teacher Details" modal className="p-fluid" footer={postDialogFooter} onHide={hideDialog}>
                        {teacher.photo && <img src={teacher.photo} alt={teacher.photo} width="150" className="mt-0 mx-auto mb-5 block shadow-2" />}

                        <div className="field">
                            <label htmlFor="first_name">First Name</label>
                            <InputText id="first_name" value={teacher.first_name} onChange={(e) => onInputChange(e, 'first_name')} required autoFocus className={classNames({ 'p-invalid': submitted && !teacher.first_name })} />
                            {submitted && !teacher.first_name && <small className="p-invalid">First Name is required.</small>}
                        </div>

                        <div className="field">
                            <label htmlFor="last_name">Last Name</label>
                            <InputText id="last_name" value={teacher.last_name} onChange={(e) => onInputChange(e, 'last_name')} required rows={3} cols={20} />
                        </div>

                        <div className="field">
                            <label htmlFor="email">Email</label>
                            <InputText id="email" value={teacher.email} onChange={(e) => onInputChange(e, 'email')} required rows={3} cols={20} />
                        </div>

                        <div className="field">
                            <label htmlFor="phone">Phone</label>
                            <InputText id="phone" value={teacher.phone} onChange={(e) => onInputChange(e, 'phone')} required rows={3} cols={20} />
                        </div>

                        <div className="field">
                            <label htmlFor="description">Description</label>
                            <InputTextarea id="description" value={teacher.description} onChange={(e) => onInputChange(e, 'description')} required rows={3} cols={20} />
                        </div>

                        <h5>Programs</h5>
                        {
                            teacherDialog && programs && programs.map((program) => {
                                return (
                                    <div key={program.id} className="field-radiobutton">
                                        <RadioButton inputId={program.id} name="program" value={program} onChange={(e) => setSelectedProgram(e.value)} checked={selectedProgram.id === program.id} disabled={program.id === 'R'} />
                                        <label htmlFor={program.id}>{program.title}</label>
                                    </div>
                                )
                            })
                        }

                        <div className="field">
                            <label htmlFor="status">Image</label>
                            <FileUpload mode="basic" name="status" url="https://primefaces.org/primereact/showcase/upload.php" accept="image/*" customUpload uploadHandler={customBase64Uploader} auto />
                        </div>

                    </Dialog>

                    <Dialog visible={deleteTeacherDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteTeacherDialogFooter} onHide={hideDeleteTeacherDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {teacher && <span>Are you sure you want to delete <b>{teacher.title}</b>?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Teachers, comparisonFn);