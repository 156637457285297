import axios from 'axios';

const baseUrl = "https://vbcf-backend.techknowgram.net/api";

export class EventService {

    getProductsSmall() {
        return axios.get('assets/demo/data/products-small.json').then(res => res.data.data);
    }

    getEvents() {
        return axios.get(baseUrl + '/events').then(res => res.data);
    }

    createEvent(event) {
        return axios
            .post(baseUrl + '/events', event)
            .then(response => response)
        // .catch(err => console.log(err))
        // .finally(() => this.loading = false)
    }

    deleteEvent(id) {
        return axios
            .delete(baseUrl + `/events/${id}`)
            .then(response => response);
    }

    updateEvent(id, event) {
        return axios
            .patch(baseUrl + `/events/${id}`, event)
            .then((res) => res);
    }

    getProductsWithOrdersSmall() {
        return axios.get('assets/demo/data/products-orders-small.json').then(res => res.data.data);
    }
}