import classNames from 'classnames';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Skeleton } from 'primereact/skeleton';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import React, { useEffect, useRef, useState } from 'react';
import { VideoService } from '../service/CategoryService';
import { ProgramService } from '../service/ProgramService';

const Categories = () => {

    let emptyVideo = {
        id: null,
        name: '',
        slug: '',
        parent: 0,
        sort: 0,
    };

    const [videos, setVideos] = useState(null);
    const [programs, setPrograms] = useState(null);
    const [videoDialog, setVideoDialog] = useState(false);
    const [deleteVideoDialog, setDeleteVideoDialog] = useState(false);
    const [video, setVideo] = useState(emptyVideo);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);


    const [selectedProgram, setSelectedProgram] = useState(null);

    useEffect(() => {
        const videoService = new VideoService();
        videoService.getVideos().then(data => setVideos(data));

        const programService = new ProgramService();
        programService.getPrograms().then(data => {setPrograms(data); setSelectedProgram(data[0]) });
    }, []);

    const openNew = () => {
        setVideo(emptyVideo);
        setSubmitted(false);
        setVideoDialog(true);
    }

    const hideDialog = () => {
        setSubmitted(false);
        setVideoDialog(false);
    }

    const hideDeleteVideoDialog = () => {
        setDeleteVideoDialog(false);
    }

    const saveVideo = () => {
        setSubmitted(true);

        if (video.name.trim()) {
            let _videos = [...videos];
            let _video = { ...video };
            if (video.id) {
                const index = findIndexById(video.id);

                // _video.program_id= selectedProgram.id
                _videos[index] = _video;

                //network request here
                const videoService = new VideoService();
                videoService.updateVideo(video.id, _video).then(res => {
                    console.log(res);
                    if (res.status === 200) {
                        setVideos(_videos);
                        setVideoDialog(false);
                        setVideo(emptyVideo);
                        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Category Updated', life: 3000 });
                    }
                });

            }
            else {
                _video.id = createId();
                // _video.program_id= selectedProgram.id;

                //network request here
                const videoService = new VideoService();
                videoService.createVideo(_video).then(res => {
                    console.log(res);
                    if (res.status === 200) {
                        _videos.unshift(_video);
                        setVideos(_videos);
                        setVideoDialog(false);
                        setVideo(emptyVideo);
                        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Category Created', life: 3000 });
                    }
                });

            }
        }
    }

    const editVideo = (video) => {
        setVideo({ ...video });
        setSelectedProgram(programs.find(program => program.id === video.program_id))
        setVideoDialog(true);
    }

    const confirmDeleteVideo = (video) => {
        setVideo(video);
        setDeleteVideoDialog(true);
    }

    const deleteVideo = () => {
        let _videos = videos.filter(val => val.id !== video.id);

        //network request here
        const videoService = new VideoService();
        videoService.deleteVideo(video.id).then(res => {
            console.log(res);
            if (res.status === 200) {
                setVideos(_videos);
                setDeleteVideoDialog(false);
                setVideo(emptyVideo);
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Video Deleted', life: 3000 });
            }
        });

    }

    const findIndexById = (id) => {
        let index = -1;
        for (let i = 0; i < videos.length; i++) {
            if (videos[i].id === id) {
                index = i;
                break;
            }
        }

        return index;
    }

    const createId = () => {
        let id = '';
        let chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        for (let i = 0; i < 5; i++) {
            id += chars.charAt(Math.floor(Math.random() * chars.length));
        }
        return id;
    }

    const exportCSV = () => {
        dt.current.exportCSV();
    }

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _video = { ...video };
        _video[`${name}`] = val;
        setVideo(_video);
    }


    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="New" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                    {/* <Button label="Delete" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedPosts || !selectedPosts.length} /> */}
                </div>
            </React.Fragment>
        )
    }

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                {/* <FileUpload mode="basic" accept="image/*" maxFileSize={1000000} label="Import" chooseLabel="Import" className="mr-2 inline-block" /> */}
                <Button label="Export" icon="pi pi-upload" className="p-button-help" onClick={exportCSV} />
            </React.Fragment>
        )
    }

    const titleBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Category Name</span>
                {rowData.name}
            </>
        );
    }

    const dateBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Parent Category</span>
                {rowData.parent}
            </>
        );
    }

    const customerBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Category Slug</span>
                {rowData.slug}
            </>
        );
    }

    const totalProductBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Sort Order</span>
                {rowData.sort}
            </>
        );
    }

    const totalPriceBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Total Price</span>
                {rowData.total}
            </>
        );
    }

    const contentBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Description</span>
                {rowData.description.substr(0, 50) + '...'}
            </>
        );
    }


    const channelBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Channel</span>
                {rowData.channel}
            </>
        );
    }

    const durationBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Duration</span>
                {rowData.duration}
            </>
        );
    }


    const urlBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">URL</span>
                <span className={`status-${rowData.url.toLowerCase()}`}>{rowData.url}</span>
            </>
        )
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editVideo(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-warning mt-2" onClick={() => confirmDeleteVideo(rowData)} />
            </div>
        );
    }

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Manage Categories</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );

    const videoDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={saveVideo} />
        </>
    );
    const deleteVideoDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteVideoDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteVideo} />
        </>
    );

    const placeholders = Array.from({ length: 10 });

    const bodyTemplate = () => {
        return <Skeleton></Skeleton>
    }



    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>



                    {!videos && (
                        <DataTable value={placeholders} className="p-datatable-striped">
                            <Column field="name" header="Category Name" style={{ width: '20%' }} body={bodyTemplate}></Column>
                            <Column field="parent" header="Parent Category" style={{ width: '20%' }} body={bodyTemplate}></Column>
                            <Column field="slug" header="Slug" style={{ width: '20%' }} body={bodyTemplate}></Column>
                            <Column field="sort" header="Sort" style={{ width: '20%' }} body={bodyTemplate}></Column>
                        </DataTable>
                    )}

                    {videos && (

                        <DataTable ref={dt} value={videos}
                            dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} categories"
                            globalFilter={globalFilter} emptyMessage="No categories found." header={header} responsiveLayout="scroll">
                            <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                            <Column field="name" header="Category Name" sortable body={titleBodyTemplate} headerStyle={{ width: '17%', minWidth: '10rem' }}></Column>
                            <Column field="parent" header="Parent Category" sortable body={dateBodyTemplate} headerStyle={{ width: '17%', minWidth: '10rem' }}></Column>
                            <Column field="slug" header="Slug" body={customerBodyTemplate} headerStyle={{ width: '17%', minWidth: '10rem' }}></Column>
                            <Column field="sort" header="Sort" body={totalProductBodyTemplate} sortable headerStyle={{ width: '17%', minWidth: '8rem' }}></Column>
                           
                            <Column body={actionBodyTemplate}></Column>
                        </DataTable>

                    )}



                    <Dialog visible={videoDialog} style={{ width: '680px' }} header="Category Details" modal className="p-fluid" footer={videoDialogFooter} onHide={hideDialog}>

                        <div className="field">
                            <label htmlFor="name">Category Name</label>
                            <InputText id="name" value={video.name} onChange={(e) => onInputChange(e, 'name')} required autoFocus className={classNames({ 'p-invalid': submitted && !video.name })} />
                            {submitted && !video.name && <small className="p-invalid">Name is required.</small>}
                        </div>

                        <div className="field">
                            <label htmlFor="parent">Parent Categoey</label>
                            <InputTextarea id="parent" value={video.parent} onChange={(e) => onInputChange(e, 'parent')} required rows={3} cols={20} />
                        </div>

                        <div className="field">
                            <label htmlFor="slug">Slug</label>
                            <InputTextarea id="slug" value={video.slug} onChange={(e) => onInputChange(e, 'slug')} required rows={3} cols={20} />
                        </div>

                        <div className="field">
                            <label htmlFor="sort">Sort</label>
                            <InputTextarea id="sort" value={video.sort} onChange={(e) => onInputChange(e, 'sort')} required rows={3} cols={20} />
                        </div>


                        {/* <h5>Programs</h5>
                        {
                            videoDialog && programs && programs.map((program) => {
                                return (
                                    <div key={program.id} className="field-radiobutton">
                                        <RadioButton inputId={program.id} name="program" value={program} onChange={(e) => setSelectedProgram(e.value)} checked={selectedProgram.id === program.id} disabled={program.id === 'R'} />
                                        <label htmlFor={program.id}>{program.title}</label>
                                    </div>
                                )
                            })
                        } */}

                    </Dialog>

                    <Dialog visible={deleteVideoDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteVideoDialogFooter} onHide={hideDeleteVideoDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {video && <span>Are you sure you want to delete <b>{video.title}</b>?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Categories, comparisonFn);