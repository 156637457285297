import classNames from 'classnames';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Skeleton } from 'primereact/skeleton';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import React, { useEffect, useRef, useState } from 'react';
import { VideoService } from '../service/CategoryService';
import { PostService } from '../service/PostService';


const Posts = () => {

    let emptyPost = {
        id: null,
        sku: '',
        name: '',
        description: '',
        regular_price: '',
        discount_price:"",
        quantity:'',
        image: null,
        product_status_id: 3,
        categoryId: 2,
        taxable:0,
    };

    const [selectedCity, setSelectedCity] = useState(null);

    const beforeSetSelectedCity=(value)=> {
        setSelectedCity(value);

        const val = value;
        let _post = { ...post };
        _post[`categoryId`] = val.id;

        setPost(_post);

    }

    const [cities, setCities] = useState([]);
    // const cities = [
    //     { name: 'New York', code: 'NY' },
    //     { name: 'Rome', code: 'RM' },
    //     { name: 'London', code: 'LDN' },
    //     { name: 'Istanbul', code: 'IST' },
    //     { name: 'Paris', code: 'PRS' }
    // ];

    const [posts, setPosts] = useState(null);
    const [postDialog, setPostDialog] = useState(false);
    const [deletePostDialog, setDeletePostDialog] = useState(false);
    const [deletePostsDialog, setDeletePostsDialog] = useState(false);
    const [post, setPost] = useState(emptyPost);
    const [selectedPosts, setSelectedPosts] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [base64Image, setBase64Image] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);

    useEffect(() => {
        const postService = new PostService();
        postService.getPosts().then(data => setPosts(data));
        const categoryService = new VideoService();
        categoryService.getVideos().then(data => setCities(data));
    }, []);

    const openNew = () => {
        setPost(emptyPost);
        setSubmitted(false);
        setPostDialog(true);
    }

    const hideDialog = () => {
        setSubmitted(false);
        setPostDialog(false);
    }

    const hideDeletePostDialog = () => {
        setDeletePostDialog(false);
    }

    const hideDeletePostsDialog = () => {
        setDeletePostsDialog(false);
    }

    const savePost = () => {
        setSubmitted(true);

        if (post.name.trim()) {
            let _posts = [...posts];
            let _post = { ...post };
            if (post.id) {
                const index = findIndexById(post.id);
                _post.image = base64Image;
                _posts[index] = _post;

                //network request here
                const postService = new PostService();
                postService.updatePost(post.id, _post).then(res => {
                    console.log(res);
                    if (res.status === 200) {
                        // _posts.push({..._post, image: base64Image});
                        setPosts(_posts);
                        setPostDialog(false);
                        setPost(emptyPost);
                        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Post Updated', life: 3000 });
                    }
                });

            }
            else {
                _post.id = createId();
                _post.image = base64Image;

                //network request here
                const postService = new PostService();
                postService.createPost(_post).then(res => {
                    console.log(res);
                    if (res.status === 200) {
                        _posts.unshift(_post);
                        setPosts(_posts);
                        setPostDialog(false);
                        setPost(emptyPost);
                        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Post Created', life: 3000 });
                    }
                });

            }
        }
    }

    const editPost = (post) => {
        setPost({ ...post, categoryId: selectedCity });
        setBase64Image(post.image);
        setPostDialog(true);
    }

    const confirmDeletePost = (post) => {
        setPost(post);
        setDeletePostDialog(true);
    }

    const deletePost = () => {
        let _posts = posts.filter(val => val.id !== post.id);

        //network request here
        const postService = new PostService();
        postService.deletePost(post.id).then(res => {
            console.log(res);
            if (res.status === 200) {
                setPosts(_posts);
                setDeletePostDialog(false);
                setPost(emptyPost);
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Post Deleted', life: 3000 });
            }
        });

    }

    const findIndexById = (id) => {
        let index = -1;
        for (let i = 0; i < posts.length; i++) {
            if (posts[i].id === id) {
                index = i;
                break;
            }
        }

        return index;
    }

    const createId = () => {
        let id = '';
        let chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        for (let i = 0; i < 5; i++) {
            id += chars.charAt(Math.floor(Math.random() * chars.length));
        }
        return id;
    }

    const exportCSV = () => {
        dt.current.exportCSV();
    }

    const deleteSelectedPosts = () => {
        let _posts = posts.filter(val => !selectedPosts.includes(val));
        setPosts(_posts);
        setDeletePostsDialog(false);
        setSelectedPosts(null);
        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Posts Deleted', life: 3000 });
    }

    //no need for now. since post has no category yet
    // const onCategoryChange = (e) => {
    //     let _product = { ...product };
    //     _product['category'] = e.value;
    //     setProduct(_product);
    // }

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _post = { ...post };
        _post[`${name}`] = val;

        setPost(_post);
    }


    // no need for now since posts has no number fields
    // const onInputNumberChange = (e, name) => {
    //     const val = e.value || 0;
    //     let _product = { ...product };
    //     _product[`${name}`] = val;

    //     setProduct(_product);
    // }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="New" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                    {/* <Button label="Delete" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedPosts || !selectedPosts.length} /> */}
                </div>
            </React.Fragment>
        )
    }

    const customBase64Uploader = async (event) => {
        // convert file to base64 encoded
        const file = event.files[0];
        const reader = new FileReader();
        let blob = await fetch(file.objectURL).then(r => r.blob()); //blob:url
        reader.readAsDataURL(blob);
        reader.onloadend = function () {
            const base64data = reader.result;
            console.log(base64data);
            setBase64Image(base64data);
            setPost({...post, imageUrl: base64data});
        }
    }

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                {/* <FileUpload mode="basic" accept="image/*" maxFileSize={1000000} label="Import" chooseLabel="Import" className="mr-2 inline-block" /> */}
                <Button label="Export" icon="pi pi-upload" className="p-button-help" onClick={exportCSV} />
            </React.Fragment>
        )
    }

    const skuBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Sku</span>
                {rowData.sku}
            </>
        );
    }

    const nameBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Name</span>
                {rowData.name.substr(0, 50) +'...'}
            </>
        );
    }

    const imageBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Image</span>
                <img src={rowData.imageUrl} alt={rowData.imageUrl} className="shadow-2" width="100" />
            </>
        )
    }

    const descriptionBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Description</span>
                {rowData.description}
            </>
        );
    }

    const priceBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Regular Price</span>
                {rowData.regular_price}
            </>
        );
    }


    const discountBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Discount Price</span>
                <span className={`product-badge status-${rowData.discount_price}`}>{rowData.discount_price}</span>
            </>
        )
    }
    const quantityBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Quantity</span>
               {rowData.quantity}
            </>
        )
    }

    const productIdBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Product Id</span>
               {rowData.product_status_id}
            </>
        )
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editPost(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-warning mt-2" onClick={() => confirmDeletePost(rowData)} />
            </div>
        );
    }

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Manage Products</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );

    const postDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={savePost} />
        </>
    );
    const deletePostDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeletePostDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deletePost} />
        </>
    );
    const deletePostsDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeletePostsDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedPosts} />
        </>
    );

    const placeholders = Array.from({ length: 10 });

    const bodyTemplate = () => {
        return <Skeleton></Skeleton>
    }



    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>



                    {!posts && (
                        <DataTable value={placeholders} className="p-datatable-striped">
                            <Column field="sku" header="Title" style={{ width: '16%' }} body={bodyTemplate}></Column>
                            <Column field="name" header="Content" style={{ width: '16%' }} body={bodyTemplate}></Column>
                            <Column field="image" header="Image" style={{ width: '16%' }} body={bodyTemplate}></Column>
                            <Column field="discount_price" header="Author" style={{ width: '16%' }} body={bodyTemplate}></Column>
                            <Column field="regular_price" header="Excerpt" style={{ width: '16%' }} body={bodyTemplate}></Column>
                            <Column field="product_status_id" header="Status" style={{ width: '16%' }} body={bodyTemplate}></Column>
                            <Column field="description" header="Excerpt" style={{ width: '16%' }} body={bodyTemplate}></Column>
                            <Column field="quantity" header="Status" style={{ width: '16%' }} body={bodyTemplate}></Column>
                        </DataTable>
                    )}

                    {posts && (

                        <DataTable ref={dt} value={posts} selection={selectedPosts} onSelectionChange={(e) => setSelectedPosts(e.value)}
                            dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} posts"
                            globalFilter={globalFilter} emptyMessage="No posts found." header={header} responsiveLayout="scroll">
                            <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                            <Column field="sku" header="Sku" sortable body={skuBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                            <Column field="name" header="Name" sortable body={nameBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                            <Column header="Image" body={imageBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                            <Column field="description" header="Description" body={descriptionBodyTemplate} sortable headerStyle={{ width: '14%', minWidth: '8rem' }}></Column>
                            <Column field="regular_price" header="Regular Price" sortable body={priceBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                            <Column field="discount_price" header="Discount Price" body={discountBodyTemplate} sortable headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                            <Column field="quantity" header="Quantity" body={quantityBodyTemplate} sortable headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                            <Column field="product_status_id" header="Product Id " body={productIdBodyTemplate} sortable headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                            <Column body={actionBodyTemplate}></Column>
                        </DataTable>

                    )}



                    <Dialog visible={postDialog} style={{ width: '680px' }} header="Post Details" modal className="p-fluid" footer={postDialogFooter} onHide={hideDialog}>
                        {post.imageUrl && <img src={post.imageUrl} alt={post.imageUrl} width="150" className="mt-0 mx-auto mb-5 block shadow-2" />}

                        <div className="field">
                            <label htmlFor="title">Sku</label>
                            <InputText id="title" value={post.sku} onChange={(e) => onInputChange(e, 'sku')} required autoFocus className={classNames({ 'p-invalid': submitted && !post.sku })} />
                            {submitted && !post.sku && <small className="p-invalid">Title is required.</small>}
                        </div>


                        <div className='field'>
                        <Dropdown value={selectedCity} onChange={(e) => {beforeSetSelectedCity(e.value)}} options={cities} optionLabel="name" 
                placeholder="Select a Category" className="w-full md:w-14rem" />
                        </div>

                        <div className="field">
                            <label htmlFor="content">Name</label>
                            <InputTextarea id="content" value={post.name} onChange={(e) => onInputChange(e, 'name')} required rows={3} cols={20} />
                        </div>

                        <div className="field">
                            <label htmlFor="excerpt">Description</label>
                            <InputTextarea id="excerpt" value={post.description} onChange={(e) => onInputChange(e, 'description')} required rows={3} cols={20} />
                        </div>

                        <div className="field">
                            <label htmlFor="author">Regular Price</label>
                            <InputTextarea id="author" value={post.regular_price} onChange={(e) => onInputChange(e, 'regular_price')} required rows={3} cols={20} />
                        </div>

                        <div className="field">
                            <label htmlFor="status">Discount Price</label>
                            <InputTextarea id="status" value={post.discount_price} onChange={(e) => onInputChange(e, 'discount_price')} required rows={3} cols={20} />
                        </div>
                        <div className="field">
                            <label htmlFor="status">Quantity</label>
                            <InputTextarea id="status" value={post.quantity} onChange={(e) => onInputChange(e, 'quantity')} required rows={3} cols={20} />
                        </div>
                        <div className="field">
                            <label htmlFor="status">Product</label>
                            <InputTextarea id="status" value={post.product_status_id} onChange={(e) => onInputChange(e, 'product')} required rows={3} cols={20} />
                        </div>

                        <div className="field">
                            <label htmlFor="status">Image</label>
                            <InputTextarea id="status" value={post.imageUrl} onChange={(e) => onInputChange(e, 'imageUrl')} required rows={3} cols={20} />
                        </div>
                        <div className="field">
                            <label htmlFor="status">Taxable</label>
                            <InputTextarea id="status" value={post.taxable} onChange={(e) => onInputChange(e, 'taxable')} required rows={3} cols={20} />
                        </div>

                    </Dialog>

                    <Dialog visible={deletePostDialog} style={{ width: '450px' }} header="Confirm" modal footer={deletePostDialogFooter} onHide={hideDeletePostDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {post && <span>Are you sure you want to delete <b>{post.title}</b>?</span>}
                        </div>
                    </Dialog>

                    <Dialog visible={deletePostsDialog} style={{ width: '450px' }} header="Confirm" modal footer={deletePostsDialogFooter} onHide={hideDeletePostsDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {post && <span>Are you sure you want to delete the selected posts?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Posts, comparisonFn);
