import classNames from 'classnames';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { FileUpload } from 'primereact/fileupload';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { RadioButton } from 'primereact/radiobutton';
import { Skeleton } from 'primereact/skeleton';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import React, { useEffect, useRef, useState } from 'react';
import { EcmemberService } from '../service/EcmemberService';


const Ecmembers = () => {
    let emptyEcmember = {
        id: null,
        title: '',
        img: null,
        description: '',
        section: '',
    };

    const [ecMembers, setEcmembers] = useState(null);
    const [section, setSection] = useState("2020-21");
    const [EcmemberDialog, setEcmemberDialog] = useState(false);
    const [deleteEcmemberDialog, setDeleteEcmemberDialog] = useState(false);
    const [deleteEcmembersDialog, setDeleteEcmembersDialog] = useState(false);
    const [ecMember, setEcmember] = useState(emptyEcmember);
    const [selectedEcmembers, setSelectedEcmembers] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [base64Image, setBase64Image] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);

    useEffect(() => {
        const Ecmember = new EcmemberService();
        Ecmember.getEcmember().then(data => setEcmembers(data));
    }, []);

    const openNew = () => {
        setEcmember(emptyEcmember);
        setSubmitted(false);
        setEcmemberDialog(true);
    }

    const hideDialog = () => {
        setSubmitted(false);
        setEcmemberDialog(false);
    }

    const hideDeleteEcmemberDialog = () => {
        setDeleteEcmemberDialog(false);
    }

    const hideDeleteEcmembersDialog = () => {
        setDeleteEcmembersDialog(false);
    }

    const saveEcmember = () => {
        setSubmitted(true);

        if (ecMember.title.trim()) {
            let _Ecmembers = [...ecMembers];
            let _Ecmember = { ...ecMember };
            if (ecMember.id) {
                const index = findIndexById(ecMember.id);
                _Ecmember.img = base64Image;
                _Ecmember.section = section;
                _Ecmembers[index] = _Ecmember;

                //network request here
                const ecmemberService = new EcmemberService();
                ecmemberService.updateEcmember(ecMember.id, _Ecmember).then(res => {
                    console.log(res);
                    if (res.status === 200) {
                        // _Ecmembers.push({..._Ecmember, image: base64Image});
                        setEcmembers(_Ecmembers);
                        setEcmemberDialog(false);
                        setEcmember(emptyEcmember);
                        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Ecmember Updated', life: 3000 });
                    }
                });

            }
            else {
                _Ecmember.id = createId();
                _Ecmember.img = base64Image;
                _Ecmember.section = section;

                //network request here
                const ecmemberService = new EcmemberService();
                ecmemberService.createEcmember(_Ecmember).then(res => {
                    console.log(res);
                    if (res.status === 200) {
                        _Ecmembers.unshift(_Ecmember);
                        setEcmembers(_Ecmembers);
                        setEcmemberDialog(false);
                        setEcmember(emptyEcmember);
                        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Ecmember Created', life: 3000 });
                    }
                });

            }
        }
    }

    const editEcmember = (Ecmember) => {
        setEcmember({ ...Ecmember });
        setBase64Image(Ecmember.img);
        setSection(Ecmember.section);
        setEcmemberDialog(true);
    }

    const confirmDeleteEcmember = (Ecmember) => {
        setEcmember(Ecmember);
        setDeleteEcmemberDialog(true);
    }

    const deleteEcmember = () => {
        let _Ecmembers = ecMembers.filter(val => val.id !== ecMember.id);

        //network request here
        const ecmemberService = new EcmemberService();
        ecmemberService.deleteEcmember(ecMember.id).then(res => {
            console.log(res);
            if (res.status === 200) {
                setEcmembers(_Ecmembers);
                setDeleteEcmemberDialog(false);
                setEcmember(emptyEcmember);
                setSection("2020-21");
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Ecmember Deleted', life: 3000 });
            }
        });

    }

    const findIndexById = (id) => {
        let index = -1;
        for (let i = 0; i < ecMembers.length; i++) {
            if (ecMembers[i].id === id) {
                index = i;
                break;
            }
        }

        return index;
    }

    const createId = () => {
        let id = '';
        let chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        for (let i = 0; i < 5; i++) {
            id += chars.charAt(Math.floor(Math.random() * chars.length));
        }
        return id;
    }

    const exportCSV = () => {
        dt.current.exportCSV();
    }


    const deleteSelectedEcmembers = () => {
        let _Ecmembers = Ecmembers.filter(val => !selectedEcmembers.includes(val));
        setEcmembers(_Ecmembers);
        setDeleteEcmembersDialog(false);
        setSelectedEcmembers(null);
        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Ecmember Deleted', life: 3000 });
    }

    //no need for now. since Ecmember has no category yet
    // const onCategoryChange = (e) => {
    //     let _product = { ...product };
    //     _product['category'] = e.value;
    //     setProduct(_product);
    // }

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _Ecmember = { ...ecMember };
        _Ecmember[`${name}`] = val;

        setEcmember(_Ecmember);
    }


    // no need for now since Ecmembers has no number fields
    // const onInputNumberChange = (e, name) => {
    //     const val = e.value || 0;
    //     let _product = { ...product };
    //     _product[`${name}`] = val;

    //     setProduct(_product);
    // }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="New" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                    {/* <Button label="Delete" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedEcmembers || !selectedEcmembers.length} /> */}
                </div>
            </React.Fragment>
        )
    }

    const customBase64Uploader = async (event) => {
        // convert file to base64 encoded 
        const file = event.files[0];
        const reader = new FileReader();
        let blob = await fetch(file.objectURL).then(r => r.blob()); //blob:url
        reader.readAsDataURL(blob);
        reader.onloadend = function () {
            const base64data = reader.result;
            console.log(base64data);
            setBase64Image(base64data);
            setEcmember({ ...ecMember, img: base64data });
        }
    }

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                {/* <FileUpload mode="basic" accept="image/*" maxFileSize={1000000} label="Import" chooseLabel="Import" className="mr-2 inline-block" /> */}
                <Button label="Export" icon="pi pi-upload" className="p-button-help" onClick={exportCSV} />
            </React.Fragment>
        )
    }

    const titleBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Title</span>
                {rowData.title}
            </>
        );
    }


    const imageBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Image</span>
                <img src={rowData.img} alt={rowData.img} className="shadow-2" width="100" />
            </>
        )
    }

    const descriptionBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Descreption</span>
                {rowData.description}
            </>
        );
    }
    const sectionBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Section</span>
                {rowData.section}
            </>
        );
    }

    //no need
    // const categoryBodyTemplate = (rowData) => {
    //     return (
    //         <>
    //             <span className="p-column-title">Category</span>
    //             {rowData.category}
    //         </>
    //     );
    // }

    // const ratingBodyTemplate = (rowData) => {
    //     return (
    //         <>
    //             <span className="p-column-title">Reviews</span>
    //             <Rating value={rowData.rating} readonly cancel={false} />
    //         </>
    //     );
    // }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editEcmember(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-warning mt-2" onClick={() => confirmDeleteEcmember(rowData)} />
            </div>
        );
    }

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Manage EC Members</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );

    const EcmemberDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={saveEcmember} />
        </>
    );
    const deleteEcmemberDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteEcmemberDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteEcmember} />
        </>
    );
    const deleteEcmembersDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteEcmembersDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedEcmembers} />
        </>
    );

    const placeholders = Array.from({ length: 10 });

    const bodyTemplate = () => {
        return <Skeleton></Skeleton>
    }



    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>
                    {!ecMembers && (
                        <DataTable value={placeholders} className="p-datatable-striped">
                            <Column field="title" header="Title" style={{ width: '16%' }} body={bodyTemplate}></Column>
                            <Column field="image" header="Image" style={{ width: '16%' }} body={bodyTemplate}></Column>
                            <Column field="description" header="Description" style={{ width: '16%' }} body={bodyTemplate}></Column>
                        </DataTable>
                    )}

                    {ecMembers && (

                        <DataTable ref={dt} value={ecMembers} selection={selectedEcmembers} onSelectionChange={(e) => setSelectedEcmembers(e.value)}
                            dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Ecmembers"
                            globalFilter={globalFilter} emptyMessage="No Ecmembers found." header={header} responsiveLayout="scroll">
                            <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                            <Column field="title" header="Title" sortable body={titleBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                            <Column header="Image" body={imageBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                            <Column field="description" header="Description" body={descriptionBodyTemplate} sortable headerStyle={{ width: '14%', minWidth: '8rem' }}></Column>
                            <Column field="section" header="Session" body={sectionBodyTemplate} sortable headerStyle={{ width: '14%', minWidth: '8rem' }}></Column>
                            <Column body={actionBodyTemplate}></Column>
                        </DataTable>

                    )}



                    <Dialog visible={EcmemberDialog} style={{ width: '680px' }} header="Ecmember Details" modal className="p-fluid" footer={EcmemberDialogFooter} onHide={hideDialog}>
                        {ecMember.img && <img src={ecMember.img} alt={ecMember.img} width="150" className="mt-0 mx-auto mb-5 block shadow-2" />}

                        <div className="field">
                            <label htmlFor="title">Title</label>
                            <InputText id="title" value={ecMember.title} onChange={(e) => onInputChange(e, 'title')} required autoFocus className={classNames({ 'p-invalid': submitted && !ecMember.title })} />
                            {submitted && !ecMember.title && <small className="p-invalid">Title is required.</small>}
                        </div>


                        <div className="field">
                            <label htmlFor="description">descreption</label>
                            <InputTextarea id="description" value={ecMember.description} onChange={(e) => onInputChange(e, 'description')} required rows={3} cols={20} />
                        </div>

                        <div className="field">
                            <label htmlFor="status">Image</label>
                            <FileUpload mode="basic" name="status" url="https://primefaces.org/primereact/showcase/upload.php" accept="image/*" customUpload uploadHandler={customBase64Uploader} auto />
                        </div>
                        <h5>Session</h5>
                        <div className="field-radiobutton">
                            <RadioButton inputId="session1" value="2020-21" name="session" onChange={(e) => setSection(e.value)} checked={section === '2020-21'} />
                            <label htmlFor="session1">2020-21</label>
                            <RadioButton inputId="session2" value="2022-23" name="session" onChange={(e) => setSection(e.value)} checked={section === '2022-23'} />
                            <label htmlFor="session2">2022-23</label>
                        </div>
                    </Dialog>

                    <Dialog visible={deleteEcmemberDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteEcmemberDialogFooter} onHide={hideDeleteEcmemberDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {ecMember && <span>Are you sure you want to delete <b>{ecMember.title}</b>?</span>}
                        </div>
                    </Dialog>

                    <Dialog visible={deleteEcmembersDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteEcmembersDialogFooter} onHide={hideDeleteEcmembersDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {ecMember && <span>Are you sure you want to delete the selected Ecmembers?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Ecmembers, comparisonFn);