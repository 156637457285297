import axios from 'axios';

const baseUrl = "https://vbcf-backend.techknowgram.net/api";

export class TeacherService {

    getProductsSmall() {
        return axios.get('assets/demo/data/products-small.json').then(res => res.data.data);
    }

    getTeachers() {
        return axios.get(baseUrl + '/teachers').then(res => res.data);
    }

    createTeacher(teacher) {
        return axios
            .post(baseUrl + '/teachers', teacher)
            .then(response => response)
        // .catch(err => console.log(err))
        // .finally(() => this.loading = false)
    }

    deleteTeacher(id) {
        return axios
            .delete(baseUrl + `/teachers/${id}`)
            .then(response => response);
    }

    updateTeacher(id, teacher) {
        return axios
            .patch(baseUrl + `/teachers/${id}`, teacher)
            .then((res) => res);
    }

    getProductsWithOrdersSmall() {
        return axios.get('assets/demo/data/products-orders-small.json').then(res => res.data.data);
    }
}