import axios from 'axios';

const baseUrl = "https://vbcf-backend.techknowgram.net/api";

export class StudentService {

    getProductsSmall() {
        return axios.get('assets/demo/data/products-small.json').then(res => res.data.data);
    }

    getStudents() {
        return axios.get(baseUrl + '/students').then(res => res.data);
    }

    createStudent(post) {
        return axios
            .post(baseUrl + '/students', post)
            .then(response => response)
        // .catch(err => console.log(err))
        // .finally(() => this.loading = false)
    }

    deleteStudent(id) {
        return axios
            .delete(baseUrl + `/students/${id}`)
            .then(response => response);
    }

    updateStudent(id, student) {
        return axios
            .patch(baseUrl + `/students/${id}`, student)
            .then((res) => res);
    }

    getProductsWithOrdersSmall() {
        return axios.get('assets/demo/data/products-orders-small.json').then(res => res.data.data);
    }
}