import classNames from 'classnames';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { FileUpload } from 'primereact/fileupload';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Skeleton } from 'primereact/skeleton';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import React, { useEffect, useRef, useState } from 'react';
import { ProgramService } from '../service/ProgramService';


const Programs = () => {

    let emptyProgram = {
        id: null,
        title: '',
        subtitle: '',
        description: '',
        banner_image: '',
        about_image: '',
    };

    const [programs, setPrograms] = useState(null);
    const [programDialog, setProgramDialog] = useState(false);
    const [deleteProgramDialog, setDeleteProgramDialog] = useState(false);
    const [program, setProgram] = useState(emptyProgram);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [base64ImageBanner, setBase64ImageBanner] = useState(null);
    const [base64ImageAbout, setBase64ImageAbout] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);

    useEffect(() => {
        const programService = new ProgramService();
        programService.getPrograms().then(data => setPrograms(data));
    }, []);

    const openNew = () => {
        setProgram(emptyProgram);
        setSubmitted(false);
        setProgramDialog(true);
    }

    const hideDialog = () => {
        setSubmitted(false);
        setProgramDialog(false);
        setBase64ImageBanner(null);
        setBase64ImageAbout(null);
    }

    const hideDeleteProgramDialog = () => {
        setDeleteProgramDialog(false);
    }

    const saveProgram = () => {
        setSubmitted(true);

        if (program.title.trim()) {
            let _programs = [...programs];
            let _program = { ...program };
            if (program.id) {
                const index = findIndexById(program.id);
                _program.banner_image = base64ImageBanner;
                _program.about_image = base64ImageAbout;
                _programs[index] = _program;

                //network request here
                const programService = new ProgramService();
                programService.updateProgram(program.id, _program).then(res => {

                    if (res.status === 200) {
                        setPrograms(_programs);
                        setProgramDialog(false);
                        setProgram(emptyProgram);
                        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Program Updated', life: 3000 });
                    }
                });

            }
            else {
                _program.id = createId();
                _program.banner_image = base64ImageBanner;
                _program.about_image = base64ImageAbout;

                //network request here
                const programService = new ProgramService();
                programService.createProgram(_program).then(res => {
                    console.log(res);
                    if (res.status === 200) {
                        _programs.unshift(_program);
                        setPrograms(_programs);
                        setProgramDialog(false);
                        setProgram(emptyProgram);
                        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Program Created', life: 3000 });
                    }
                });

            }
        }
    }

    const editProgram = (program) => {
        setProgram({ ...program });
        setBase64ImageBanner(program.banner_image);
        setBase64ImageAbout(program.about_image);
        setProgramDialog(true);
    }

    const confirmDeleteProgram = (program) => {
        setProgram(program);
        setDeleteProgramDialog(true);
    }

    const deleteProgram = () => {
        let _programs = programs.filter(val => val.id !== program.id);

        //network request here
        const programService = new ProgramService();
        programService.deleteProgram(program.id).then(res => {
            console.log(res);
            if (res.status === 200) {
                setPrograms(_programs);
                setDeleteProgramDialog(false);
                setProgram(emptyProgram);
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Program Deleted', life: 3000 });
            }
        });

    }

    const findIndexById = (id) => {
        let index = -1;
        for (let i = 0; i < programs.length; i++) {
            if (programs[i].id === id) {
                index = i;
                break;
            }
        }

        return index;
    }

    const createId = () => {
        let id = '';
        let chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        for (let i = 0; i < 5; i++) {
            id += chars.charAt(Math.floor(Math.random() * chars.length));
        }
        return id;
    }

    const exportCSV = () => {
        dt.current.exportCSV();
    }


    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _program = { ...program };
        _program[`${name}`] = val;

        setProgram(_program);
    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="New" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                    {/* <Button label="Delete" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedPosts || !selectedPosts.length} /> */}
                </div>
            </React.Fragment>
        )
    }

    const customBase64UploaderBanner = async (event) => {
        // convert file to base64 encoded 
        const file = event.files[0];
        const reader = new FileReader();
        let blob = await fetch(file.objectURL).then(r => r.blob()); //blob:url
        reader.readAsDataURL(blob);
        reader.onloadend = function () {
            const base64data = reader.result;
            console.log(base64data);
            setBase64ImageBanner(base64data);
            setProgram({ ...program, banner_image: base64data });
        }
    }

    const customBase64UploaderAbout = async (event) => {
        // convert file to base64 encoded 
        const file = event.files[0];
        const reader = new FileReader();
        let blob = await fetch(file.objectURL).then(r => r.blob()); //blob:url
        reader.readAsDataURL(blob);
        reader.onloadend = function () {
            const base64data = reader.result;
            console.log(base64data);
            setBase64ImageAbout(base64data);
            setProgram({ ...program, about_image: base64data });
        }
    }

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                {/* <FileUpload mode="basic" accept="image/*" maxFileSize={1000000} label="Import" chooseLabel="Import" className="mr-2 inline-block" /> */}
                <Button label="Export" icon="pi pi-upload" className="p-button-help" onClick={exportCSV} />
            </React.Fragment>
        )
    }

    const titleBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Title</span>
                {rowData.title}
            </>
        );
    }

    const subTitleBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Subtitle</span>
                {rowData.subtitle}
            </>
        );
    }

    const contentBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Description</span>
                {rowData.description.substr(0, 50) + '...'}
            </>
        );
    }

    const bannerImageBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Banner</span>
                <img src={rowData.banner_image} alt={rowData.banner_image} className="shadow-2" width="100" />
            </>
        )
    }

    const aboutImageBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">About Image</span>
                <img src={rowData.about_image} alt={rowData.about_image} className="shadow-2" width="100" />
            </>
        )
    }


    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editProgram(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-warning mt-2" onClick={() => confirmDeleteProgram(rowData)} />
            </div>
        );
    }

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Manage Programs</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );

    const programDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={saveProgram} />
        </>
    );
    const deleteProgramDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProgramDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteProgram} />
        </>
    );

    const placeholders = Array.from({ length: 10 });

    const bodyTemplate = () => {
        return <Skeleton></Skeleton>
    }



    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>



                    {!programs && (
                        <DataTable value={placeholders} className="p-datatable-striped">
                            <Column field="title" header="Title" style={{ width: '16%' }} body={bodyTemplate}></Column>
                            <Column field="subtitle" header="Subtitle" style={{ width: '16%' }} body={bodyTemplate}></Column>
                            <Column field="banner_image" header="Banner" style={{ width: '16%' }} body={bodyTemplate}></Column>
                            <Column field="about_image" header="Profile" style={{ width: '16%' }} body={bodyTemplate}></Column>
                            <Column field="description" header="Description" style={{ width: '16%' }} body={bodyTemplate}></Column>
                        </DataTable>
                    )}

                    {programs && (

                        <DataTable ref={dt} value={programs}
                            dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} programs"
                            globalFilter={globalFilter} emptyMessage="No programs found." header={header} responsiveLayout="scroll">
                            <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                            <Column field="title" header="Title" sortable body={titleBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                            <Column field="subtitle" header="Subtitle" sortable body={subTitleBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                            <Column header="Banner" body={bannerImageBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                            <Column header="Profile" body={aboutImageBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                            <Column field="description" header="Description" sortable body={contentBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                            <Column body={actionBodyTemplate}></Column>
                        </DataTable>

                    )}



                    <Dialog visible={programDialog} style={{ width: '680px' }} header="Program Details" modal className="p-fluid" footer={programDialogFooter} onHide={hideDialog}>
                        {program.banner_image && <img src={program.banner_image} alt={program.banner_image} width="150" className="mt-0 mx-auto mb-5 block shadow-2" />}

                        {program.about_image && <img src={program.about_image} alt={program.about_image} width="150" className="mt-0 mx-auto mb-5 block shadow-2" />}

                        <div className="field">
                            <label htmlFor="title">Title</label>
                            <InputText id="title" value={program.title} onChange={(e) => onInputChange(e, 'title')} required autoFocus className={classNames({ 'p-invalid': submitted && !program.title })} />
                            {submitted && !program.title && <small className="p-invalid">Title is required.</small>}
                        </div>

                        <div className="field">
                            <label htmlFor="subtitle">Subtitle</label>
                            <InputText id="subtitle" value={program.subtitle} onChange={(e) => onInputChange(e, 'subtitle')} required rows={3} cols={20} />
                        </div>

                        <div className="field">
                            <label htmlFor="description">Description</label>
                            <InputTextarea id="description" value={program.description} onChange={(e) => onInputChange(e, 'description')} required rows={3} cols={20} />
                        </div>

                        <div className="field">
                            <label htmlFor="banner">Banner Image</label>
                            <FileUpload mode="basic" name="banner" url="https://primefaces.org/primereact/showcase/upload.php" accept="image/*" customUpload uploadHandler={customBase64UploaderBanner} auto />
                        </div>

                        <div className="field">
                            <label htmlFor="about">About Image</label>
                            <FileUpload mode="basic" name="about" url="https://primefaces.org/primereact/showcase/upload.php" accept="image/*" customUpload uploadHandler={customBase64UploaderAbout} auto />
                        </div>

                    </Dialog>

                    <Dialog visible={deleteProgramDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteProgramDialogFooter} onHide={hideDeleteProgramDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {program && <span>Are you sure you want to delete <b>{program.title}</b>?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Programs, comparisonFn);