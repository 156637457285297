import axios from "axios";

const baseUrl = "https://vbcf-backend.techknowgram.net/api";
export class FooterService {

    getProductsSmall() {
        return axios.get('assets/demo/data/products-small.json').then(res => res.data.data);
    }

    getFooter() {
        return axios.get(baseUrl + '/footer').then(res => res.data);
    }

    createFooter(Footer) {
        return axios
            .post(baseUrl + '/footer', Footer)
            .then(response => response)
        // .catch(err => console.log(err))
        // .finally(() => this.loading = false)
    }

    deleteFooter(id) {
        return axios
            .delete(baseUrl + `/footer/${id}`)
            .then(response => response);
    }

    updateFooter(id, Footer) {
        return axios
            .patch(baseUrl + `/footer/${id}`, Footer)
            .then((res) => res);
    }

    getProductsWithOrdersSmall() {
        return axios.get('assets/demo/data/products-orders-small.json').then(res => res.data.data);
    }
}